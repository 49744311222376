<template>
	<div class="order-payment">
		<div
			class="order-payment__type"
			v-if="loyaltyAgreement"
		>
			<div class="order-payment__type-check">
				<label class="checkbox-label">
					<input
						@change="changePointsInOrder"
						type="checkbox"
						class="checkbox"
						v-model="showPoints"
					>
					<span class="checkbox-icon"></span>
					<span class="g-input__name">Оплата баллами</span>
				</label>
			</div>

			<div v-if="showPoints">
				<form
					@submit.prevent.stop="setPoints"
					class="order-payment__type-form"
				>
					<label
						class="g-input"
						:class="{'error' : showPointsError}"
					>
						<span class="g-input__name">Укажите кол-во баллов:</span>
						<input
							@focus="setPointsError(false)"
							@input="showApplyButton"
							name="name"
							type="number"
							class="g-input__input"
							min="0"
							v-model="points"
						>
						<div class="g-input__sub">Не больше {{ maxPoints }}</div>
					</label>
					<button
						class="button button_red"
						v-if="showApply"
					>Применить
					</button>
					<div
						class="button button_red"
						@click="cancelPoints"
						v-if="!showApply"
					>Отменить
					</div>
				</form>
				<div
					class="user-points-count-block"
					v-if="allPoints"
				>
					<span class="order-form__all-points-text">На вашем счету </span>
					<span class="order-form__all-points-value">{{ addThousandSeparator(allPoints) }} баллов</span>
				</div>
			</div>
		</div>

		<div class="order-payment__type">
			<div class="order-payment__type-check">
				<label class="checkbox-label">
					<input
						type="checkbox"
						class="checkbox"
						v-model="showPromocode"
					>
					<span class="checkbox-icon"></span>
					<span class="g-input__name">Указать промокод:</span>
				</label>
				<div class="order-payment__info-icon">
					<svg
						width="20"
						height="20"
					>
						<use xlink:href="#order-form-prom__info-icon"></use>
					</svg>
					<div class="order-payment__tooltip">При использовании промокода другие скидки могут быть
						недоступны.
					</div>
				</div>
			</div>

			<form
				@submit.prevent.stop="updatePromocode"
				class="order-payment__type-form-wr"
				v-if="showPromocode"
			>
				<div class="order-payment__type-form">
					<label
						class="g-input"
						:class="{ 'error': promocodeError }"
					>
						<span class="g-input__name">Введите номер промокода:</span>
						<input
							@focus="showPromocodeAcceptText = false; SET_PROMOCODE_ERROR('')"
							v-model="promocode"
							name="promocode"
							type="text"
							class="g-input__input"
						>
					</label>
					<button class="button button_red">Применить</button>
				</div>
				<div
					class="promocode-accept-text"
					v-if="showPromocodeAcceptText"
					:class="{error: promocodeError}"
				>
					{{ promocodeError || 'Промокод применен' }}
				</div>
			</form>
		</div>

	</div>
</template>
<script>
import {addThousandSeparator, replaceHyphens} from "@/helpers";
import {mapActions, mapMutations, mapGetters} from "vuex";

export default {
	props: ['maxPoints', 'allPoints', 'loyaltyAgreement'],
	data()
	{
		return {
			showPromocode: false,
			showPromocodeAcceptText: false,
			showPoints: true,
			points: 0,
			promocode: '',
			showPointsError: false,
			showApply: true
		}
	},
	computed:
		{
			...mapGetters({
				promocodeError: "order/getPromocodeError",
			}),
		},
	methods:
		{
			...mapMutations({
				setPromocode: 'setPromocode',
				SET_PROMOCODE_ERROR: 'order/SET_PROMOCODE_ERROR'
			}),
			...mapActions({
				checkPromocode: 'order/checkPromocode'
			}),
			addThousandSeparator,
			setPoints()
			{
				if (this.points > this.maxPoints)
				{
					this.setPointsError(true);
					return;
				}
				if (this.points > 0)
				{
					this.showApply = false;
				}

				this.$store.commit('order/setStateValue', {key: 'points', value: this.points});
				this.$emit('updateOrderData');
			},

			cancelPoints()
			{
				this.$store.commit('order/setStateValue', {key: 'points', value: 0});
				this.$emit('updateOrderData');
				this.showApply = true;
			},

			showApplyButton()
			{
				this.showApply = true;
			},

			async updatePromocode()
			{
				if (!this.promocode.trim()) return;

				const promocode = replaceHyphens(this.promocode);

				this.setPromocode(this.showPromocode ? promocode : '');

				const data = await this.checkPromocode(promocode);

				this.showPromocodeAcceptText = true;

				if (!data.success) return;

				this.$emit('updateOrderData');
			},
			/**
			 * устанавливает промокод при загрузке страницы
			 */
			async initialPromocode()
			{
				this.promocode = this.$store.state.auth.promocode || this.$route.query.promo || '';

				if (!this.promocode) return;

				this.showPromocode = true;
				await this.updatePromocode();
			},

			changePointsInOrder()
			{
				if (this.showPoints)
				{
					this.points = this.maxPoints;
					this.showApply = true;
					return;
				}

				this.points = 0
				this.setPoints();
			},
			setPointsError(status)
			{
				this.showPointsError = !!status;
			},
		},
	watch:
		{
			'maxPoints'()
			{
				if (!this.$store.state.user)
				{
					this.showPoints = false;
				}
				this.points = this.maxPoints;
			},
			'loyaltyAgreement'()
			{
				if (!this.loyaltyAgreement)
				{
					this.showPoints = false;
					this.$store.commit('order/setStateValue', {key: 'points', value: 0});
					this.$emit('updateOrderData');
				} else if (this.$store.state.user)
				{
					this.showPoints = true;
					this.points = this.maxPoints;
					this.showApply = true;
				}
			}
		},
	async mounted()
	{
		await this.initialPromocode();
	}
}
</script>
<style lang="scss">
.order-payment
{
	display: flex;
	gap: 24px;
}

.order-payment__type
{margin-bottom: 17px; }

.order-payment__type-check
{
	display: flex;
	align-items: center;
	position: relative;
}

.order-payment__info-icon
{
	fill: transparent;
	line-height: 0;
	@include ease(.125s);

	svg
	{
		margin-left: 12px;

		&:hover
		{
			fill: $textColor;
			stroke: #fff;
		}

		&:hover + .order-payment__tooltip
		{
			opacity: 1;
			z-index: 1;
		}
	}
}

.order-payment__tooltip
{
	z-index: -1;
	position: absolute;
	background-color: #fff;
	border-radius: 10px;
	width: 276px;
	padding: 29px 20px 30px;
	top: -132px;
	left: 6px;
	opacity: 0;
	box-shadow: 0px 13px 20px rgba(0, 0, 0, 0.05);
	font-size: 16px;
	line-height: 120%;
	text-align: center;
	color: #3d4248;
	@include ease(.125s);

	&:after
	{
		content: '';
		width: 20px;
		height: 20px;
		background-color: #fff;
		transform: rotate(45deg);
		position: absolute;
		bottom: -9px;
		right: 128px;
	}
}

.order-payment__type-form-wr
{
	display: flex;
	flex-direction: column;
}

.order-payment__type-form
{
	margin-top: 18px;
	display: flex;
	align-items: flex-end;

	.g-input
	{
		width: 226px;
		margin-right: 15px;
	}

	.button
	{
		font-family: "GoogleSans-Regular", sans-serif;
		width: 152px;
		height: 48px;
		font-size: 16px;
		cursor: pointer;
		border-radius: 8px;
		font-weight: 700;
		line-height: 20px;
	}
}

.promocode-accept-text
{
	color: #666;

	&.error
	{color: $red;}
}

.user-points-count-block
{
	margin-top: 22px;
}

.order-form__all-points-text
{
	font-size: 16px;
	line-height: 20px;
	color: #666;
	opacity: 0.7;
}

.order-form__all-points-value
{
	font-size: 16px;
	line-height: 20px;
	color: $green;
	padding-left: 6px;
	font-weight: bolder;
}

@media (max-width: 900px)
{
	.order-payment
	{
		flex-direction: column;
	}
}

@media (max-width: 767px)
{
	.order-payment__type-form
	{
		flex-direction: column;
		align-items: flex-start;
		width: 100%;
		gap: 12px;

		.g-input
		{
			width: 100%;
		}

		.button
		{
			width: 100%;
		}
	}
}
</style>